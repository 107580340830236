import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Text,
  Alert,
  Group,
  Title,
  Stack,
  Affix,
  LoadingOverlay,
  ActionIcon,
  Divider,
  Paper,
  Loader,
  Modal,
  Center,
  Badge,
} from "@mantine/core";
import { useAuth } from "../lib/authUserContext";
import { IconArrowRight } from "@tabler/icons";
import { useLocalDB } from "../lib/localDBContext";
import axios from "axios";
import { env } from "../envvar";
import { API_URL } from './../env';

export default function Account({setOpened}) {
  const [cancelDialog, setCancelDialog] = useState(false);
  const { authUser } = useAuth();
  const { config } = useLocalDB();
  const [loading, setLoading] = useState(true);
  const [sub, setSub] = useState({});
  const status = authUser.sub_status;
  useEffect(() => {
    axios
      .get(`${API_URL}/me/subscription`, {
        headers: {
          Authorization: `Bearer ${authUser.jwt}`,
        },
      })
      .then(({ data }) => {
        setSub(data.data);
      })
      .finally(() => {
        
        setLoading(false);
      });
  }, []);

  function onSubCancel() {
    if (env == "dev" || config?.pg_enabled) {
      setLoading(true);
      axios
        .get(`${API_URL}/pg/cancel-sub`, {
          headers: {
            Authorization: `Bearer ${authUser.jwt}`,
          },
        })
        .then(() => {
          setOpened(false)
          alert('Your subscription has been canceled')
        })
        .finally(() => {
          setCancelDialog(false)
          setLoading(false);
        });
    } else {
      window.open(
        `https://wa.me/916375063918?text=Hi%2C%20My%20client%20id%20is%20${authUser.uid}%2C%20I%20want%20to%20cancel%20my%20subscription`,
        "_blank",
        "noopener,noreferrer"
      );
    }
  }
  return (
    <Box>
      <LoadingOverlay visible={loading} />
      <Group position="apart">
        {sub?.item?.amount && <Title order={3}>₹{(sub.item.amount/100)}/month</Title>}
        <Badge color="dark">{status}</Badge>
      </Group>

      <Group mt="md" position="apart">
        <Text color={"dimmed"} align="left" size="xs">
          Renews on {new Date(authUser.sub_end).toDateString()}
        </Text>
        {status == "active" && (
          <Button
            compact
            color="red"
            variant="outline"
            align="right"
            onClick={() => setCancelDialog(true)}
          >
            Cancel subscription
          </Button>
        )}
      </Group>

      {authUser.sub_link && (
        <>
          <Divider mt="xs" />
          <Text size="sm" color={"dimmed"}>
            Your subscription link :
          </Text>
          <Group position="apart">
            <Text
              component="a"
              href={authUser.sub_link}
              target="_blank"
              color={"blue"}
            >
              {authUser.sub_link}
            </Text>
            <ActionIcon
              component="a"
              href={authUser.sub_link}
              target="_blank"
              color={"blue"}
              variant="light"
              radius={"xl"}
              size="md"
            >
              <IconArrowRight size={20} />
            </ActionIcon>
          </Group>
        </>
      )}

      <Modal
        overlayBlur={0}
        centered
        withCloseButton={false}
        opened={cancelDialog}
        onClose={() => setCancelDialog(false)}
      >
        <Stack>
          <Center>
            <Title centered order={5}>
              Are you sure you want to cancel your subscription ?, It will delete your Virtual Pc forever.
            </Title>
          </Center>

          <Group grow>
            <Button onClick={onSubCancel} color="red" variant="outline">
              Yes, Cancel it
            </Button>
            <Button
              onClick={() => setCancelDialog(false)}
              color="blue"
              variant="filled"
            >
              No, Do not cancel
            </Button>
          </Group>
        </Stack>
      </Modal>
    </Box>
  );
}
