import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Card,
  Text,
  Loader,
  Group,
  Button,
  Stepper,
  Center,
  Modal,
  Box,
  Divider,
  Image,
  Grid,
  UnstyledButton,
  Title,
  Stack,
  Notification,
  Space,
  ThemeIcon,
} from "@mantine/core";
import { useInterval } from "@mantine/hooks";
import { useAuth } from "../lib/authUserContext";
import { X, RotateClockwise, ArrowNarrowRight, Mail } from "tabler-icons-react";
import { useNavigate } from "react-router-dom";
import { getAuth } from 'firebase/auth';
import firebase from './../lib/Firebase';

const VerifyEmailModal = (props) => {
  const navigate = useNavigate();
  const [seconds, setSeconds] = useState(30);
  const interval = useInterval(() => setSeconds((s) => s - 1), 1000);
  const [loading, setLoading] = useState(false);
  const [throwVerr, setThrowVerr] = useState(false);
  const { authUser, resendEmailVerificationCode, reloadAuthUserData } =
    useAuth();

  function resendEmail() {
    if (interval.active) return;
    else {
      resendEmailVerificationCode();
      interval.start();
    }
  }
  useEffect(() => {
    if(seconds == 0) interval.stop();
  }, [interval]);

  useEffect(() => {
    return interval.stop;
  }, []);

  async function checkIfVerified() {
    setLoading(true);
    await reloadAuthUserData()
    function isVerified(){
      return getAuth(firebase).currentUser.emailVerified
    }
    async function check() {
      const v = isVerified()
      if (v) {
        navigate("/home");
        props.setOpened(false);
      } else {
        setThrowVerr(true)
      };
      setLoading(false);
      return v;
    }
    check();
  }
  return (
    <>
      <Modal
        size="sm"
        opened={props.opened}
        closeOnClickOutside={false}
        closeOnEscape={false}
        withCloseButton={false}
        onClose={() => props.setOpened(false)}
        overlayBlur={11}
        overlayOpacity={0.1}
      >
        <Text align="center" size="xl" weight={600}>
          Verify your email address
        </Text>
        <Text align="center" color="dimmed">
          A link has been sent to your email address {authUser?.email}
        </Text>

        <Text pt="xs" size={"xs"} align="center" color="dimmed">
          Try checking the spam folder
        </Text>
        <Center mt={10} mb={30}>
          <Image src="https://media0.giphy.com/media/THsI9Zk6EpXiKIPC2I/giphy.gif" />
        </Center>
        {interval.active && (
          <Text>
            Resend verification email in <b>{seconds}</b> seconds
          </Text>
        )}
        {throwVerr && (
        <Notification onClose={() => setThrowVerr(false)} icon={<X size={18} />} color="red">
          Email not verified, Please verify it Or reload the page
        </Notification>
        
      )}
      <Space h="md"/>
        <Group position="center">
          <Button
            disabled={interval.active}
            onClick={resendEmail}
            leftIcon={<RotateClockwise />}
            variant="light"
          >
            Resend email
          </Button>
          <Button loading={loading} onClick={checkIfVerified} variant="filled">
            I have verified it
          </Button>
        </Group>
        
        
      </Modal>
      
    </>
  );
};
export default VerifyEmailModal;
