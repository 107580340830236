import { createContext, useContext } from 'react'
import useFirebaseAuth from './hooks/useFirebaseAuth';
import * as firebaseAuth from 'firebase/auth';
const AuthUserContext = createContext({
  authUser: null,
  loading: true,
  signInWithEmailAndPassword: async (email, password) => {},
  createUserWithEmailAndPassword: async (email, password) => {},
  resendEmailVerificationCode: () => {},
  reloadAuthUserData:()=>{},
  signOut: async () => {},
  resetUserPassword:()=>{},
  confirmPasswordResetCode:()=>{},
  changeUserEmailAddress:()=>{},
  updateUser:()=>{}
});
const AProvider = AuthUserContext.Provider
export function AuthUserProvider({ children }) {
  const auth = useFirebaseAuth();
  return (<AProvider value={auth} >{children}</AProvider>)
}
// custom hook to use the authUserContext and access authUser and loading
export const useAuth = () => useContext(AuthUserContext);