import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Center,
  Alert,
  Group,
  Title,
  Stack,
  Affix,
  LoadingOverlay,
  ActionIcon,
  Divider,
  Paper,
  Loader
} from "@mantine/core";
import {
  IconAlertCircle,
  IconBrandWhatsapp,
  IconBusinessplan,
  IconMail,
  IconWallet
} from "@tabler/icons";
import { RingProgress, Text } from "@mantine/core";
import { useLocalDB } from "./../../lib/localDBContext";
import { useNavigate } from 'react-router-dom';
import { useAuth } from "../../lib/authUserContext";
import axios from 'axios';
import { API_URL, SUPPORT_EMAIL } from './../../env';
import VmList from './../../components/VmList';
import { env } from './../../envvar';

export default function Home() {
  const { vms, refresh, config } = useLocalDB()
  const { authUser, loading } = useAuth()
  const navigate = useNavigate()
  const [setup, setSetup] = useState(null)
  const [subLoading, setSubLoading] = useState(false)
  // useEffect(() => {
  //   axios.post('http://localhost:5004/app-catch-up/us-central1/api', {
  //     "title": `your@rmail signedup for LightPC`,
  //     "topic": {
  //       "name": "LightPc",
  //       "img": "https://res.cloudinary.com/pul/image/upload/v1661428571/lightpc.png"
  //     },
  //     data: [{
  //       title: 'Name',
  //       body: "Pul",
  //       icon: '0xe491'
  //     }]
  //   }).then((res) => {
  //     console.log(res)
  //   }).catch(console.error)
  // }, [])

  function onEmailClick() {
    window.open(`mailto://${SUPPORT_EMAIL},pulkitgottoolkit@gmail.com?cc=itsnikhilsaini270@gmail.com`, '_blank', 'noopener,noreferrer')
  }
  function onWhatsappClick() {
    window.open("https://wa.me/916375063918?text=Hey%2C%20I%20want%20to%20talk%20to%20you%20about%20Light%20Pc", '_blank', 'noopener,noreferrer')
  }
  function onSubscribe() {
    if (env == 'dev' || config.pg_enabled) {
      // subscribe()
      navigate('/checkout')
    } else {
      window.open(
        `https://wa.me/916375063918?text=Hi%2C%20My%20client%20id%20is%20${authUser.uid}%2C%20I%20want%20to%20subscribe%20to%20the%20VM%20service`,
        "_blank",
        "noopener,noreferrer"
      );
    }
  }
  function subscribe() {
    setSubLoading(true)
    axios.post(`${API_URL}/pg/subscribe`, { sub_plan: 'sub_plan_basic' }, {
      headers: {
        Authorization: `Bearer ${authUser.jwt}`,
      },
    })
      .catch(alert)
      .finally(() => setSubLoading(false))
  }

  const onSetupClick = () => {
    setSetup('loading')
    axios.post(`${API_URL}/assign-vm`, {}, {
      headers: {
        Authorization: `Bearer ${authUser.jwt}`,
      },
    }).then((res) => {
      refresh()
      setSetup('created')
    })
      .finally(() => {
        setSetup('error')
      })
  }
  const SubAlert = () => (
    <Alert
      icon={<IconBusinessplan size={16} />}
      title="Inactive subscription"
      color="red"
    >
      <Text align="left">
        You are not subscribed to the service, Please subscribe or pay your
        bill if due
        <Button mt="sm" color="red" fullWidth onClick={onSubscribe} >{authUser.sub_status == "active" ? "Refresh" : "Subscribe"}</Button>
      </Text>
    </Alert>
  );
  const SubAuthAlert = () => (
    <Alert
      icon={<Loader size={16} />}
      title="Transaction waiting for user response"
    >
      <Text >
        {authUser.sub_status == "authenticated" ? "Attempting to charge aganist your payment method" : `Please checkout on the link sent to your email ${authUser.email} or click on button down below`}

      </Text>
      <Text size={'sm'} color={"dimmed"}>
        Our confirmation will take around 1-2 minutes
      </Text>
      {authUser.sub_link && <Button component="a" href={authUser.sub_link} target="_blank" mt="sm" fullWidth >Checkout</Button>}

    </Alert>
  );
  const SubStatusAlert = () => (
    <Alert
      icon={<IconWallet size={16} />}
      title={`Your current subscription status is moved to ${authUser.sub_status}, Please pay your
      bill if due.`}
      color="red"
    >
      <Text size="xs">
        Check your email {authUser.email} for invoices and payment link.
      </Text>
      <Button color="red" mt="sm" fullWidth onClick={onSubscribe} >Checkout</Button>
    </Alert>
  );
  const SetupAlert = () => (
    <Alert
      icon={<IconAlertCircle size={16} />}
      title="Welcome dear user!"
      color="blue"
    >
      <Text align="left">
        You currently don't have a vps, Continue to create your virtual computer, It would take a couple of minutes.
        <Button mt="sm" color="blue" fullWidth onClick={onSetupClick} >Create my virtual pc</Button>
      </Text>
    </Alert>
  );
  const sts = authUser.sub_status
  return (
    <div style={{
      width: '100%',
      height: '100%',
      backgroundImage: `url(https://res.cloudinary.com/pul/image/upload/v1659252974/fbads-vm-web-bg.png)`,
      // backgroundPosition:'center',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundSize: '500px 500px',
      backgroundRepeat: 'repeat',
      position: 'relative'
    }}>

      <div style={{
        borderRadius: '1rem',
        padding: '1rem',
        boxShadow:
          "0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.02)",
        backgroundColor: 'white', width: "98vw", maxWidth: "500px"
      }}>

        {(!loading && authUser && (!authUser.sub_status || authUser.sub_status == "unsubscribed")) && (<SubAlert />)}
        {(!loading && authUser && (authUser.sub_status == "created" || authUser.sub_status == "authenticated")) && (<SubAuthAlert />)}
        {(!loading && sts == "cancelled" || sts == "paused" || sts == "halted" || sts == "pending" || sts == "expired" || sts == "completed") && (<SubStatusAlert />)}

        {(!loading && authUser.sub_status == "active" && vms.length <= 0) && (<SetupAlert />)}
        <LoadingOverlay visible={setup == 'loading' || loading || subLoading} overlayBlur={1} />
        {(!loading && !!authUser?.uid) && (<VmList userId={authUser.uid} />)}
        <Divider mb="xs"/>
        <Stack position="center" spacing={2} mb="xs">
        {(!loading && authUser.sub_status == "active" && vms.length >= 1) && ( <Text align="center"  color={"dimmed"} size="sm" >Use pin <b>654321</b>  on remotedesktop.com</Text>)}
         
          {/* <Text align="center" mt="sm" mb="sm" color={"dimmed"} size="sm" >•</Text> */}
          <Text align="center"  color={"dimmed"} size="sm" >Need help ?</Text>
        </Stack>
        {/* <Text >Highlight <Text inherit component="span" color="blue" weight={700}>something</Text>in title</Text> */}
        <Group position="center" grow>

          <Button onClick={onEmailClick} variant="light" color="grape" leftIcon={<IconMail size={14} />}>
            Email
          </Button>
          <Button onClick={onWhatsappClick} variant="light" color="green" leftIcon={<IconBrandWhatsapp size={14} />}>
            Whatsapp
          </Button>
        </Group>
      </div>

    </div>

  );
}
