import "./styles.css";
import {
  MantineProvider,
  ColorSchemeProvider,
  ColorScheme
} from "@mantine/core";
import { useState,useEffect } from "react";
import Home from "./modules/home";
import Auth from "./modules/auth";
import { AuthUserProvider } from "./lib/authUserContext";
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import ProtectedRoute from "./components/ProtectedRoute";
import { LocalDBProvider } from "./lib/localDBContext";
import Base from "./modules/base";
import Checkout from './modules/account/checkout';
import ReactGA from 'react-ga';
const TRACKING_ID = "UA-178535893-1"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);
export default function App() {
  const [colorScheme, setColorScheme] = useState("light");
  const toggleColorScheme = (value) =>
    setColorScheme(value || (colorScheme === "dark" ? "light" : "dark"));
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [])

  return (
    <Router>
      <div className="App">

        <ColorSchemeProvider
          colorScheme={colorScheme}
          toggleColorScheme={toggleColorScheme}
        >
          <MantineProvider
            withGlobalStyles
            withNormalizeCSS
            theme={{
              fontFamily: "'Poppins', sans-serif",
              headings: { fontFamily: "'Poppins', sans-serif" },
              colorScheme: colorScheme,
              colors: {
                "white": ["#FFFFFF"],
                "red-dark": ["#C10C0C"]
              }
            }}
          >
            <AuthUserProvider>
              <LocalDBProvider>
                <Routes>
                  <Route exact path='/auth' element={<Auth />}></Route>
                  <Route exact path='/home' element={
                    <ProtectedRoute>
                      <Home />
                    </ProtectedRoute>
                  }></Route>
                  <Route exact path='/checkout' element={

                    <Checkout />

                  }></Route>
                  <Route exact path='/app' element={
                    <ProtectedRoute>
                      <Home />
                    </ProtectedRoute>
                  }></Route>
                  <Route exact path='/' element={<Base />}></Route>
                </Routes>
              </LocalDBProvider>
            </AuthUserProvider>
          </MantineProvider>
        </ColorSchemeProvider>
      </div>
    </Router>
  );
}
