import { useEffect, useState } from "react";
import { Stack, Text, Center, Title, Loader } from "@mantine/core";
import { AuthenticationForm } from "./AuthForm";
import VerifyEmailModal from "../../components/VerifyEmailModal";
import axios from "axios";
import { GLITCH_URL } from "./../../env";

const Auth = () => {
  const [opened, setOpened] = useState(false);
  //  useEffect(() => {
  //   //  axios.post(`${GLITCH_URL}/new-user`, {email:"test", client_id:Date.now()})
  //   // axios.get(`${GLITCH_URL}`)
  //  }, [])
  const [error, setError] = useState(null);
  const [formType, setFormType] = useState('login');
  const toggleFormType = () => {
    setFormType((current) => (current === 'register' ? 'login' : 'register'));
    setError(null);
  };
  
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Stack style={{ width: "100%", height: "100%" }} spacing={0} justify="center" align="center">
        <Title order={3}>{formType == 'login' ? 'Login' : 'Signup'}</Title>
        <AuthenticationForm error={error} setError={setError} formType={formType}  toggleFormType={toggleFormType} setOpened={setOpened} />
        <VerifyEmailModal opened={opened} setOpened={setOpened} />
      </Stack>
    </div>
  );
};

export default Auth;
