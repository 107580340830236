import { useState, useEffect } from 'react'
import firebase from '../Firebase';
import * as firebaseAuth from 'firebase/auth';
import { collection, doc, getDoc, updateDoc, getFirestore, setDoc, onSnapshot } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { GLITCH_URL } from './../../env';
import axios from 'axios';
import { useDocumentData } from 'react-firebase-hooks/firestore';


const formatAuthUser = async (user) => {
    return {
        uid: user.uid,
        email: user.email,
        emailVerified: user.emailVerified,
        isNewUser: user.metadata.creationTime == user.metadata.lastSignInTime,
        jwt: await user?.getIdToken(true)
    }
}

export default function useFirebaseAuth() {
    const [authUser, setAuthUser] = useState(null);
    const [unForAuthUser, setUnForAuthUser] = useState(getAuth(firebase).currentUser);
    const [loading, setLoading] = useState(true);

    const authStateChanged = async (authState) => {

        if (authState) {
            setLoading(true)
            setUnForAuthUser(authState)
            getData(authState.uid)
            const formattedUser = await formatAuthUser(authState);
            setAuthUser((state) => { return { ...formattedUser, ...state } });
            setLoading(false)
        }
        else {
            setAuthUser(null)
            setLoading(false)
            return;
        }

    };

    const clear = () => {
        setAuthUser(null);
        // setLoading(true);
    };
    const signInWithEmailAndPassword = async (email, password, toSignOut) => {
        await signOut()
        return firebaseAuth.signInWithEmailAndPassword(firebaseAuth.getAuth(firebase), email, password)
    }

    const createUserWithEmailAndPassword = (email, password) => {
        
        return new Promise((resolve, reject) => {
            try {
                signOut()
                firebaseAuth.createUserWithEmailAndPassword(firebaseAuth.getAuth(firebase), email, password).then(async (val) => {
                    await signInWithEmailAndPassword(email, password)
                    firebaseAuth.sendEmailVerification(val.user)
                    setAuthUser((state) => { return { ...state, email } })
                    setData(val.user.uid, email)
                    resolve(val.user)
                    // axios.post('https://us-central1-app-catch-up.cloudfunctions.net/api', {
                    //     "title": `${email} signedup for LightPC`,
                    //     "topic": {
                    //         "name": "LightPc",
                    //         "img": "https://res.cloudinary.com/pul/image/upload/v1661428571/lightpc.png"
                    //       },
                    //     data: [{
                    //         title: 'Name',
                    //         body: val.user.displayName,
                    //         icon: '0xe491'
                    //     }]
                    // })
                }).catch(reject)
            } catch (error) {
                reject(error)
            }
        })
    }
    const resendEmailVerificationCode = () => {
        firebaseAuth.sendEmailVerification(unForAuthUser)
    }
    const reloadAuthUserData = async () => {
        return new Promise((res, rej) => {
            setLoading(true)
            firebaseAuth.reload(getAuth(firebase).currentUser).then(async(res) => {
                await firebaseAuth.reload(getAuth(firebase).currentUser)
                const user = getAuth(firebase).currentUser
                setAuthUser((state) => { return { ...state, emailVerified: user.emailVerified } });
            })
            .catch(rej)
            .finally(() => {
                res()
                setLoading(false)
            })
        })
        
    }
    const setData = async (uuid, email) => {
        const colRef = collection(getFirestore(firebase), "users");
        await setDoc(doc(colRef, uuid), {
            sub_status: "unsubscribed", // "active"
            sub_start: null,
            sub_end: null,
            uuid: uuid,
            email,
            vm_limit: 1,
            vm_count: 0,
            sub_plan: null,
            sub_link: null,
            sub_id: null
        })
    }
    const getData = async (uuid) => {
        const docRef = doc(getFirestore(firebase), "users", uuid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            const data = docSnap.data()
            setAuthUser((state) => { return { ...state, ...data } })

        } else {
            // doc.data() will be undefined in this case
            signOut()
        }
    }
    async function reauthenticate() {
        const provider = new firebaseAuth.EmailAuthProvider()
        const result = await firebaseAuth.signInWithPopup(getFirestore(firebase), provider);
        return result.user
    }
    const resetUserPassword = async (newPassword) => {
        try {
            const user = await reauthenticate()
            const res = await firebaseAuth.updatePassword(user, newPassword)
            Promise.resolve(res)
        } catch (err) {
            Promise.reject(err)
        }
    }

    const changeUserEmailAddress = async (newEmail) => {
        try {
            const user = await reauthenticate()
            const res = await firebaseAuth.updateEmail(user, newEmail)
            Promise.resolve(res)
        } catch (err) {
            Promise.reject(err)
        }
    }
    const updateUser = async (updatedFields) => {
        const colRef = collection(getFirestore(firebase), "users");
        await updateDoc(doc(colRef, authUser.uid), updatedFields);
    }
    const signOut = () =>
        firebaseAuth.getAuth(firebase).signOut().then(clear);

    useEffect(() => {
        (async () => {
            const au = getAuth(firebase).currentUser
            if (au) {
                const data = await formatAuthUser(au)
                setAuthUser((state) => { return { ...state, ...data } })
                setLoading(false)
            }
        })()
        const unsubscribe = firebaseAuth.getAuth(firebase).onAuthStateChanged(authStateChanged);
        return () => unsubscribe();
    }, []);
    useEffect(() => {

        let unsubscribe = () => { }
        if (!loading && authUser?.uid) {
            const colRef = collection(getFirestore(firebase), "users");
            unsubscribe = onSnapshot(doc(colRef, authUser.uid), (snap) => {

                const data = snap.data()

                setAuthUser((state) => { return { ...state, ...data } })

            })
        }
        return () => unsubscribe();
    }, [authUser?.uid]);

    return {
        authUser,
        loading,
        signInWithEmailAndPassword,
        createUserWithEmailAndPassword,
        resendEmailVerificationCode,
        signOut,
        reloadAuthUserData,
        resetUserPassword,
        changeUserEmailAddress,
        updateUser
    };
}