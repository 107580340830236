import { createContext, useContext, Context, useEffect, useState } from "react";
import firebase from "./Firebase";
import {
  collection,
  doc,
  getFirestore,
  setDoc,
  where,
  query,
  getDocs,
  documentId,
  updateDoc,
  deleteDoc,
  FieldValue,
  FieldPath,
  getDoc,
} from "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";
import * as firestore from "firebase/firestore";
import { useListState } from "@mantine/hooks";
import { useAuth } from "./authUserContext";
import { useLocalStorage } from "@mantine/hooks";
import _ from "lodash";
import { _getPlatformImage } from "./utils";

const prop = {
  vms: [],
  config: {},
  loading: true,
  refresh: () => {},
};
const LocalDBContext = createContext(prop);
const useLocalDBHook = () => {
  const [loading, setLoading] = useState(true);
  const auth = useAuth();
  const [value, setValue] = useLocalStorage({
    key: "data",
    defaultValue: { vms: [], config: {} },
  });
  const [config, setConfig] = useLocalStorage({
    key: "config",
    defaultValue: {},
  });
  useEffect(() => {
    (async () => {
      const docRef = doc(getFirestore(firebase), "admin", "config");
      const ref = await getDoc(docRef);
      const data = ref.data();
      setConfig(data);
    })();
  }, []);
  function load() {
    if (!auth.loading && !!auth.authUser) {
      const vmsQuery = query(
        collection(getFirestore(firebase), "vms"),
        where("author_uid", "==", auth.authUser.uid)
      );
      getDocs(vmsQuery)
        .then((docRes) => {
          setValue({
            vms: docRes.docs.map((x) => {
              const data = x.data();
              return { ...data, uid: data.uid || x.id };
            }),
          });
        })
        .finally(() => setLoading(false));
    }
  }
  useEffect(() => {

    load();
  }, [auth.loading, auth.authUser]);
  return {
    vms: value.vms || [],
    loading,
    refresh: load,
    config,
  };
};
const Provider = LocalDBContext.Provider;
export function LocalDBProvider({ children }) {
  const data = useLocalDBHook();
  return <Provider value={data}>{children}</Provider>;
}
// custom hook to use the authUserContext and access authUser and loading
export const useLocalDB = () => useContext(LocalDBContext);
