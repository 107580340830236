import * as firebase from "firebase/app";
import 'firebase/auth';
const firebaseConfig = {
  apiKey: "AIzaSyDYfm2N-HPd94Tqacy7oa1e6dsqCj1H2l8",
  authDomain: "fbads-vm.firebaseapp.com",
  projectId: "fbads-vm",
  storageBucket: "fbads-vm.appspot.com",
  messagingSenderId: "320759790077",
  appId: "1:320759790077:web:23f07a3cbbf2ddc704c194"
};

//  firebase.initializeApp(firebaseConfig)
// if a Firebase instance doesn't exist, create one

if (firebase.getApps().length == 0) {
  firebase.initializeApp(firebaseConfig)
} 

export default firebase.getApp()