import { useState, useEffect } from "react";
import {
  Box,
  Button,
  Center,
  Modal,
  Group,
  Progress,
  Stack,
  Alert,
  Badge,
  ActionIcon,
  Loader,
  Textarea,
  List,
  Code,
  LoadingOverlay,
  Grid,
  Menu,
  Indicator,
  Divider,
} from "@mantine/core";
import {
  IconBrandWhatsapp,
  IconPlayerPause,
  IconPlayerPlay,
  IconCircleCheck,
  IconCircleX,
  IconMessage,
  IconMail,
  IconPlayerStop,
  IconCheck,
  IconAlertCircle,
  IconSettings,
  IconDeviceLaptop,
  IconScreenShare,
  IconWallet,
} from "@tabler/icons";
import { RingProgress, Text } from "@mantine/core";
import desktopImage from "./../assets/desktop.png";
import axios from "axios";
import { API_URL } from "./../env";
import useFirebaseAuth from "./../lib/hooks/useFirebaseAuth";
import Account from "./Account";
export default function VmCard({ id, data }) {
  const vmId = id;
  const {
    project_id,
    uptime,
    zone,
    up_limit,
    remote_access,
    desktop_installed,
    public_ip,
  } = data;
  const limitHit = up_limit <= uptime;
  const uptimeUsedHrs = Number(uptime / 3600).toFixed(2);
  const uptimeLimitHrs = Math.floor(up_limit / 3600);
  const uptimeleftHrs = uptimeLimitHrs - uptimeUsedHrs;

  const [subDaysLeft, setSubDaysLeft] = useState(0);
  const { authUser, loading } = useFirebaseAuth();
  const [visible, setVisible] = useState(false);
  const [subStatus, setSubStatus] = useState("inactive");
  useEffect(() => {
    if (authUser?.uid) {
      setSubStatus(authUser.sub_status);
      const daysLeft = Math.floor(
        (authUser.sub_end - Date.now()) / 1000 / (3600 * 24)
      );
      setSubDaysLeft(daysLeft < 0 ? 0 : daysLeft);
    }
  }, [authUser]);
  const readStatus =
    data.status == "RUNNING"
      ? "Started"
      : data.status == "TERMINATED"
      ? "Stopped"
      : data.status;
  const [status, setStatus] = useState(readStatus || "Loading"); // Stopping // Started // Starting // Stopped // Loading
  const [remAccModal, setRemAccModal] = useState(false);
  const [accModal, setAccModal] = useState(false);
  const [setupLoading, setSetupLoading] = useState(false);
  const [sshCode, setSshCode] = useState("");

  useEffect(() => {
    axios
      .post(`${API_URL}/vm-status`, {
        vmId: vmId,
        projectId: project_id,
        zone,
      })
      .then((res) => {
        switch (res.data.status) {
          case "RUNNING":
            setStatus("Started");
            break;
          case "TERMINATED":
            setStatus("Stopped");
            break;
          default:
            setStatus(String(res.data.status));
            break;
        }
      })
      .catch(() => {
        setStatus("Error");
      });
  }, []);

  function startVm() {
    return axios.get(`${API_URL}/start-vm/${vmId}`, {
      headers: {
        Authorization: `Bearer ${authUser.jwt}`,
      },
    });
  }
  const lowStatus = String(status).toLowerCase();

  const mutualDisable =
    lowStatus == "error" ||
    limitHit ||
    subStatus != "active" ||
    lowStatus == "loading";

  const disableStartButton = lowStatus != "stopped" || mutualDisable;
  const disableStopButton = lowStatus != "started" || mutualDisable;
  function stopVm() {
    return axios.get(`${API_URL}/stop-vm/${vmId}`, {
      headers: {
        Authorization: `Bearer ${authUser.jwt}`,
      },
    });
  }
  function onStart() {
    if (!disableStartButton) {
      const pastStatus = `${status}`;
      setStatus("Starting");
      startVm()
        .then(() => {
          setStatus("Started");
          openRemoteLink();
        })
        .catch(() => setStatus(pastStatus));
    } else {
      setVisible(true);
    }
  }
  function onStop() {
    if (!disableStopButton) {
      const pastStatus = `${status}`;
      setStatus("Stopping");
      stopVm()
        .then(() => setStatus("Stopped"))
        .catch(() => {
          window.location.reload();
        });
    }
  }
  function closeModal() {
    setVisible(false);
  }
  function onSetupClick() {
    setRemAccModal(true);
  }
  function onSetupSubmit() {
    if (sshCode.length > 10) {
      if (sshCode.startsWith("DISPLAY")) {
        setSetupLoading(true);
        axios
          .post(`${API_URL}/setup-remote-access/${vmId}`, sshCode, {
            headers: {
              Authorization: `Bearer ${authUser.jwt}`,
            },
          })
          .then(() => {
            openRemoteLink();
          })
          .catch(() => {
            alert("An error occured");
          })
          .finally(() => {
            setSetupLoading(false);
            setRemAccModal(false);
          });
      } else {
        alert("Please paste the code only for Debian Linux");
      }
    } else {
      alert("Please input a valid command");
    }
  }
  function openRemoteLink() {
    window.open(
      `https://remotedesktop.google.com/access`,
      "_blank",
      "noopener,noreferrer"
    );
  }
  function onUpgradeClick() {
    window.open(
      `https://wa.me/916375063918?text=Hi%2C%20My%20VmId%20is%20${vmId}%20%2C%20I%20want%20to%20subscribe%20for%20more%20virtual%20machine%20hours`,
      "_blank",
      "noopener,noreferrer"
    );
  }
  function onSubscribeClick() {
    window.open(
      `https://wa.me/916375063918?text=Hi%2C%20My%20client%20id%20is%20${authUser.uid}%2C%20I%20want%20to%20subscribe%20to%20the%20VM%20serivce`,
      "_blank",
      "noopener,noreferrer"
    );
  }

  function StatusIcon() {
    switch (status) {
      case "Started":
        return (
          <ActionIcon size="sm" color="blue" variant="filled" radius="xl">
            <IconCheck size={20} />
          </ActionIcon>
        );
      case "Stopping":
        return (
          <ActionIcon size="sm" color="white" variant="filled" radius="xl">
            <Loader variant="bars" color="red" size={20} />
          </ActionIcon>
        );
      case "Loading":
        return (
          <ActionIcon size="sm" color="gray" variant="light" radius="xl">
            <Loader color="gray" size={20} />
          </ActionIcon>
        );
      case "Error":
        return (
          <ActionIcon size="sm" color="red" variant="filled" radius="xl">
            <IconCircleX size={20} />
          </ActionIcon>
        );
      case "Starting":
        return (
          <ActionIcon size="sm" color="white" variant="filled" radius="xl">
            <Loader variant="bars" size={20} />
          </ActionIcon>
        );
      default:
        return (
          <ActionIcon size="sm" color="red" variant="filled" radius="xl">
            <IconPlayerPause size={20} />
          </ActionIcon>
        );
    }
  }

  const StatusBadge = () => {
    return (
      <Badge
        sx={{ paddingLeft: 4, paddingRight: 7 }}
        size="lg"
        radius="xl"
        variant={
          status === "Started" || status === "Stopped" ? "filled" : "light"
        }
        color={
          status === "Started" || status === "Starting"
            ? "blue"
            : status === "Loading"
            ? "gray"
            : "red"
        }
        leftSection={StatusIcon()}
      >
        {status}
      </Badge>
    );
  };
  const DesktopAlert = () => (
    <Alert
      icon={<Loader size={16} />}
      title="We are setting up the desktop environment for you."
      color="green"
    >
      <Text align="left">
        It would take some time, Please recheck after 5-10 minutes.
      </Text>
    </Alert>
  );
  const LimitAlert = () => (
    <Alert
      icon={<IconAlertCircle size={16} />}
      title="Limit reached!"
      color="red"
    >
      <Text align="left">
        You have used all your virtual machine hours, upgrade your subscription
        to get more.
      </Text>

      <Button
        mt={"xs"}
        onClick={onUpgradeClick}
        size="md"
        color="red"
        fullWidth
      >
        Upgrade
      </Button>
    </Alert>
  );
  const SetupAlert = () => (
    <Alert
      icon={<IconAlertCircle size={16} />}
      title="Setup chrome remote desktop to access your virtual computer"
      color="blue"
    >
      <Button mt={"xs"} onClick={onSetupClick} size="md" color="blue" fullWidth>
        Continue
      </Button>
    </Alert>
  );
  const AccountModal = () => (
    <Modal title="Account" opened={accModal} onClose={() => setAccModal(false)}>
      <Account setOpened={setAccModal}/>
    </Modal>
  );
  const RemoteModal = () => (
    <Modal
      title="Configure the Chrome Remote Desktop service"
      size="65%"
      opened={remAccModal}
      onClose={() => setRemAccModal(false)}
    >
      <List type="ordered">
        <List.Item>
          On your local computer, using the Chrome browser, go to the Chrome
          Remote Desktop command line setup page: {"\n"}
          <a
            target={"_blank"}
            style={{ color: "blue" }}
            href="https://remotedesktop.google.com/headless"
          >
            https://remotedesktop.google.com/headless
          </a>
        </List.Item>
        <List.Item>
          <span>
            On the <b>Set up another computer </b> page, click <b>Begin.</b>
          </span>
        </List.Item>
        <List.Item>
          <span>
            On the <b>Download and install Chrome Remote Desktop page</b>, click
            Next.
          </span>
        </List.Item>
        <List.Item>
          {" "}
          Click <b>Authorize.</b>
        </List.Item>
        <Divider my={"sm"}/>
        <Group spacing={"xs"}>
          <span>
            You need to allow Chrome Remote Desktop to access your account. If
            you approve, the page displays a command line for <b> Debian Linux</b> that
            looks like the following:
          </span>
          <Center>
            <Code  color="blue">
              DISPLAY= /opt/google/chrome-remote-desktop/start-host \
              --code="4/xxxxxxxxxxxxxxxxxxxxxxxx" \
              --redirect-url="https://remotedesktop.google.com/_/oauthredirect"
              \ --name=$(hostname)
            </Code>
          </Center>
        </Group>
      </List>

      <Textarea
      mt="sm"
        value={sshCode}
        onChange={(event) => setSshCode(event.currentTarget.value)}
        placeholder="DISPLAY= /opt/google/chrome-remote-desktop/start-host --code="
        label="Copy the code and paste it below."
        required
      />
      <Button
        disabled={sshCode.length < 40}
        mt={"xs"}
        onClick={onSetupSubmit}
        size="md"
        color="blue"
        fullWidth
      >
        Submit
      </Button>
    </Modal>
  );

  return (
    <div>
      <LoadingOverlay visible={setupLoading} overlayBlur={2} />
      <Box
        sx={(theme) => ({
          // backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
          textAlign: "center",
          padding: theme.spacing.xs,
          borderRadius: theme.radius.md,
          borderWidth: "0.01rem",
          borderColor: theme.colors.blue[1],
          borderStyle: "solid",
        })}
      >
        <Stack justify="space-between">
          <Center>
            <StatusBadge />
          </Center>
          <RemoteModal />
          <AccountModal />
          {limitHit && <LimitAlert />}
          {!desktop_installed && <DesktopAlert />}
          {!remote_access && desktop_installed && public_ip && <SetupAlert />}
          <Box
            sx={(theme) => ({
              // backgroundColor:
              //   theme.colorScheme === "dark"
              //     ? theme.colors.dark[6]
              //     : theme.colors.blue[0],
              textAlign: "center",
              paddingRight: theme.spacing.xl,
              paddingLeft: theme.spacing.xl,
              borderRadius: theme.radius.md,
            })}
          >
            <Group position="apart">
              <Group spacing={4} align="center">
                <Text weight={700} size="xl">
                  {uptimeleftHrs} hours
                </Text>
                <Text size="sm">left of {uptimeLimitHrs} hours</Text>
              </Group>

              <Group position="center">
                <Menu>
                  <Menu.Target>
                    <ActionIcon size="md">
                      <IconSettings size={20} />
                    </ActionIcon>
                  </Menu.Target>

                  <Menu.Dropdown>
                    <Menu.Item
                      onClick={onSetupClick}
                      icon={<IconDeviceLaptop size={14} />}
                    >
                      Configure remote access
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>
                {subStatus != "active" ? (
                  <Indicator color={"red"} withBorder offset={2} size={10}>
                    <ActionIcon onClick={() => setAccModal(true)} size="md">
                      <IconWallet size={20} />
                    </ActionIcon>
                  </Indicator>
                ) : (
                  <ActionIcon onClick={() => setAccModal(true)} size="md">
                    <IconWallet size={20} />
                  </ActionIcon>
                )}

                <ActionIcon
                  component="a"
                  href="https://remotedesktop.google.com/access"
                  target={"_blank"}
                  size="md"
                  color="blue"
                >
                  <IconScreenShare size={20} />
                </ActionIcon>
              </Group>
            </Group>

            <Progress my="sm" value={100 - (100 * uptime) / up_limit} />
            <Text color={"dimmed"} align="right" size="xs">
              {subDaysLeft} days left of subscription
            </Text>
          </Box>

          <Stack align={"center"} spacing={"xs"}>
            <Button
              onClick={onStart}
              disabled={disableStartButton}
              size="md"
              fullWidth
              leftIcon={<IconPlayerPlay size={14} />}
            >
              Start
            </Button>
            <Button
              onClick={onStop}
              size="md"
              color="red"
              fullWidth
              disabled={disableStopButton}
              leftIcon={<IconPlayerPause size={14} />}
            >
              Stop
            </Button>
          </Stack>
        </Stack>
      </Box>
    </div>
  );
}
