import React from "react";
import { useAuth } from "../lib/authUserContext";
import { useEffect, useState } from "react";
import VerifyEmailModal from "./VerifyEmailModal";
import { LoadingOverlay, Center } from "@mantine/core";
import {
  BrowserRouter as Router,
  useNavigate ,
} from "react-router-dom";
const ProtectedRoute = ({ children }) => {
  const { authUser, loading } = useAuth();
  const [opened, setOpened] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
      if (!loading && !authUser) navigate("/auth");
      if (!loading && authUser?.email && !authUser?.emailVerified) setOpened(true);
  }, [authUser, loading]);

  return (
    <>
      <VerifyEmailModal opened={opened} setOpened={setOpened} />
      <LoadingOverlay visible={loading} />
      {(!loading && authUser) ? children : null}
    </>
  );
};
export default ProtectedRoute;
