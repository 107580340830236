import React, { useState,useEffect } from 'react';
import {  Mail, LockOff } from 'tabler-icons-react';
import {
  TextInput,
  PasswordInput,
  Group,
  Checkbox,
  Button,
  Paper,
  Text,
  LoadingOverlay,
  Anchor,
  useMantineTheme,
} from '@mantine/core';
import { useAuth } from '../../lib/authUserContext';
import { useNavigate } from "react-router-dom";
import { useForm } from '@mantine/form';

export function AuthenticationForm({
  noShadow,
  noPadding,
  noSubmit,
  style,
  setOpened,
  formType,
  toggleFormType,
  setError,
  error
}) {

  const [loading, setLoading] = useState(false);
  
  const [disabled, setDisabled] = useState(false);
  const theme = useMantineTheme();
  const navigate = useNavigate()
  const { createUserWithEmailAndPassword,authUser, signInWithEmailAndPassword  } = useAuth();


  useEffect(() => {
    if (authUser) navigate("/home");
  }, [authUser]);


  const form = useForm({
    initialValues: {
      email: '',
      password: '',
      confirmPassword: '',
      // termsOfService: false,
    },

    validationRules: {
    //   firstName: (value) => formType === 'login' || value.trim().length >= 2,
    //   lastName: (value) => formType === 'login' || value.trim().length >= 2,
      email: (value) => /^\S+@\S+$/.test(value),
      password: (value) => /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/.test(value),
      confirmPassword: (val, values) => formType === 'login' || val === values.password,
    },

    errorMessages: {
      email: 'Invalid email',
      password: 'Password should contain 1 number, 1 letter and at least 6 characters',
      confirmPassword: "Passwords don't match. Try again",
    },
  });

  const signup = () => createUserWithEmailAndPassword(form.values.email, form.values.password);
  const login = () => signInWithEmailAndPassword(form.values.email, form.values.password);
  

  const handleSubmit = () => {
    setLoading(true);
    setError(null);
    const req = formType == "login" ? login : signup
    req().then(() => {
      setOpened(true)

    }).catch(err => {
      
      setError(String(err))
    }).finally(() =>{ 
      setLoading(false); 
      setOpened(false) 
    })
  };

  return (
    <Paper
      p={noPadding ? 0 : 'md'}
      shadow={noShadow ? null : 'lg'}
      style={{
        position: 'relative',
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
        ...style,
      }}
    >
      <form autoComplete="on" onSubmit={form.onSubmit(handleSubmit)}>
        <LoadingOverlay visible={loading} />
        {/* {formType === 'register' && (
          <Group grow>
            <TextInput
              data-autofocus
              required
              placeholder="Your first name"
              label="First name"
              {...form.getInputProps('firstName')}
            />

            <TextInput
              required
              placeholder="Your last name"
              label="Last name"
              {...form.getInputProps('lastName')}
            />
          </Group>
        )} */}

        <TextInput
          mt="md"
          required
          placeholder="Your email"
          label="Email"
         
          icon={<Mail />}
          {...form.getInputProps('email')}
        />

        <PasswordInput
          mt="md"
          required
         
          placeholder="Password"
          label="Password"
          icon={<LockOff />}
          {...form.getInputProps('password')}
        />

        {formType === 'register' && (
          <PasswordInput
            mt="md"
            required
            label="Confirm Password"
            placeholder="Confirm password"
            icon={<LockOff />}
            {...form.getInputProps('confirmPassword')}
          />
        )}

        {/* {formType === 'register' && (
          <Checkbox
            mt="xl"
            label="I agree to Terms of service and Privacy policy"
            {...form.getInputProps('termsOfService', { type: 'checkbox' })}
          />
        )} */}

        {error && (
          <Text color="red" size="sm" mt="sm">
            {error}
          </Text>
        )}

        {!noSubmit && (
          <Group position="apart" mt="xl">
            <Anchor
              component="button"
              type="button"
              color="gray"
              onClick={toggleFormType}
              size="sm"
            >
              {formType === 'register'
                ? 'Have an account? Login'
                : "Don't have an account? Register"}
            </Anchor>

            <Button disabled={disabled} color="blue" type="submit">
              {formType === 'register' ? 'Register' : 'Login'}
            </Button>
          </Group>
        )}
      </form>
    </Paper>
  );
}