import { useState, useEffect } from "react";
import {
  Box,
  Button,
  Center,
  Container,
  Group,
  Progress,
  Stack,
  Alert,
  Badge,
  ActionIcon,
  Loader,
} from "@mantine/core";
import {
  collection,
  getFirestore,
  where,
  query,
  doc,
} from "firebase/firestore";
import {
  useCollection,
  useDocument,
  useDocumentData,
} from "react-firebase-hooks/firestore";

import firebase from "../lib/Firebase";
import VmCard from "./VmCard";
export default function VmList({ userId }) {
  const db = getFirestore(firebase);
  const vmQuery = query(
    collection(db, "vms"),
    where("author_uid", "==", userId)
  );
  const [value, loading, error] = useCollection(vmQuery, {
    snapshotListenOptions: { includeMetadataChanges: true },
  });
  // const userQuery = query(collection(db, "vms"), where("author_uid", "==", userId));
  // const [userVal, userLoading, userError] = useDocumentData(doc(db, 'users', userId), {
  //   snapshotListenOptions: { includeMetadataChanges: true },
  // });
  return (
    <div>
      {!loading &&
        value.docs.map((doc) => {
          const vm = doc.data();
          const vmId = doc.id;
          return <VmCard key={vmId} id={vmId} data={vm} />;
        })}
    </div>
  );
}
