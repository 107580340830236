import { useState, useEffect } from "react";
import {
  Box,
  Text,
  Center,
  Badge,
  Group,
  Title,
  Stack,
  List,
  ThemeIcon,
  Card,
  Indicator,
  Modal,
  Image,
  Button
} from "@mantine/core";
import {
  IconAlertCircle,
  IconBrandWhatsapp,
  IconCheck,
  IconClock,
  IconMail
} from "@tabler/icons";

import { useLocalDB } from "./../../lib/localDBContext";
import logo from "./../../assets/fbads-vm-logo.png"
import bg from "./../../assets/bg.png"
import { useNavigate } from 'react-router-dom';
import { useAuth } from "../../lib/authUserContext";
import axios from 'axios';
import ContactModal from './../../components/ContactModal';
import ReactGA from "react-ga";
export default function Base() {
  const { vms } = useLocalDB()
  const [priceDialog, setPriceDialog] = useState(false)
  const { authUser, loading } = useAuth()
  const navigate = useNavigate()
  const [priceOpen, setPriceOpen] = useState(false)

  useEffect(() => {

    //  axios.post('https://asia-east2-fbads-vm.cloudfunctions.net/api/pg/')
    //  axios.post('https://asia-east2-fbads-vm.cloudfunctions.net/api/pg/checkout')
  }, [])


  function onEmailClick() {
    window.open("mailto://pulkitgottoolkit@gmail.com", '_blank', 'noopener,noreferrer')
  }
  function onWhatsappClick() {
    window.open("https://wa.me/916375063918?text=Hey%2C%20I%20want%20to%20talk%20to%20you%20about%20the%20virtual%20machine", '_blank', 'noopener,noreferrer')
  }
  const onButtonClick = () => {
    ReactGA.event({ category: 'Call to action', action: 'Click', label: 'Get Started' });
    navigate("/home")
  }
  const openPricingDialogOpen = () => {
    ReactGA.event({ category: 'Pricing', action: 'Click' });
    navigate("/checkout")
    // setPriceDialog(true)
  }
  const openPricingDialogClose = () => {
    setPriceDialog(false)
  }
  function onTutorialClick() {
    window.open(
      "https://wa.me/916375063918?text=Hi%2C%20I%20want%20a%20live%20tutorial%20session%20for%20LightPc",
      "_blank",
      "noopener,noreferrer"
    );
  }
  return (
    <div style={{
      width: '100%',
      height: '100%',
      // flexDirection: 'column',
      //  justifyContent: 'center',
      // alignItems: 'center',
      // padding: '10px',
      // paddingTop: '13vh',
      backgroundImage: `url(${bg})`,

      //  backgroundPosition: 'bottom'
    }}>
      <ContactModal opened={priceOpen} setOpened={setPriceOpen} />
      <Stack align={"center"} pt="13vh" px="10px" pb="2rem" >


        <Stack style={{ maxWidth: '800px', width: '90vw' }} >
          <Group position="apart" >
            <Group>
              <Title order={4}>Nikhil Saini's LightPC</Title>
              <Image height={35} width={30} src={logo} />
            </Group>

            <Group spacing={0} position="center">
              <Button onClick={() => setPriceOpen(true)} radius={"xl"} compact variant="subtle" >
                <Text weight={500} color="dark" >
                  Contact
                </Text>
              </Button>
              <Button onClick={openPricingDialogOpen} radius={"xl"} compact variant="subtle" >
                <Text weight={500} color="dark">
                  Pricing
                </Text>
              </Button>

            </Group>
          </Group>
          <Group position="apart">
            <div>
              <div style={{ maxWidth: 350 }}>
                <Title mb="md" mt={"xl"} order={2} >Setup a VPS in your PC, without any technical skills</Title>
                <Text mt="xs" size="sm" style={{ fontFamily: 'Roboto' }}>Browse internet without sharing your real IP address, access it from any desktop, wherever you are.</Text>
              </div>

              <List
                mt={"xl"}
                spacing="xs"
                size="sm"
                center
                icon={
                  <ThemeIcon color="teal" variant="light" size={24} radius="xl">
                    <IconCheck size={16} />
                  </ThemeIcon>
                }
              >
                <List.Item style={{ fontFamily: 'Roboto' }}>Direct access to screen</List.Item>
                <List.Item >No technician required</List.Item>
              </List>
              <Group>

                <Indicator color="dark" inline offset={28} position="top-end" label="FREE" size={16}>
                  <Button variant="outline" onClick={onTutorialClick} size="lg" mt={"xl"} color={"dark"} radius={"xl"}>Live tutorial</Button>
                </Indicator>

                <Button onClick={onButtonClick} size="lg" mt={"xl"} color={"dark"} radius={"xl"}>Get started</Button>
              </Group>

            </div>
            <iframe src="https://player.vimeo.com/video/747263381?h=0e7fd3b6b0" style={{ borderRadius: '1rem', width: "90vw", height: "38vh", maxWidth: '400px' }} frameborder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
            {/* <iframe  src="https://www.youtube.com/embed/2J7Q6tLyd7Y" title="LightPc tutorial" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
            {/* <iframe width="560" height="315" src="https://www.youtube.com/embed/2J7Q6tLyd7Y" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
          </Group>
        </Stack>
      </Stack>
      <div style={{
        backgroundColor:'white',
        width: '100%', position: 'fixed', bottom: "1rem",
         bottom: '0', 
        // transform: 'translateX(-50%)', 
         overflow: 'visible'
      }}>
         <Stack align="center" spacing={0} >
         <Text size="sm" >© {new Date().getFullYear()} Nikhil Saini. All rights reserved.</Text>
         </Stack>
       
        {/* <Stack align="center" spacing={0} > */}
        {/* <Group spacing={0} position="center"> */}
        {/* <Button onClick={() => setPriceOpen(true)} radius={"xl"} compact variant="subtle" >
              <Text color={"gray"} weight={400} size={'xs'}>
                Contact
              </Text>
            </Button>
            <Button onClick={openPricingDialogOpen} radius={"xl"} compact variant="subtle" >
              <Text color={"gray"} weight={400} size={'xs'}>
                Pricing
              </Text>
            </Button> */}
        {/* <Button target={"_blank"} component="a" href="https://fbads-vm.netlify.app/about.html" radius={"xl"} compact variant="subtle" >
              <Text color={"gray"} weight={400} size={'xs'}>
                About
              </Text>
            </Button>


            <Button target={"_blank"} component="a" href="https://fbads-vm.netlify.app/terms.html" radius={"xl"} compact variant="subtle" >
              <Text color={"gray"} weight={400} size={'xs'}>
                Terms of service
              </Text>
            </Button>
            <Button target={"_blank"} component="a" href="https://fbads-vm.netlify.app/shipping.html" radius={"xl"} compact variant="subtle" >
              <Text color={"gray"} weight={400} size={'xs'}>
                Shipping policy
              </Text>
            </Button>

          </Group>
          <Group spacing={0} >
            <Button target={"_blank"} component="a" href="https://fbads-vm.netlify.app/privacy.html" radius={"xl"} compact variant="subtle" >
              <Text color={"gray"} weight={400} size={'xs'}>
                Privacy policy
              </Text>
            </Button>
            <Button target={"_blank"} component="a" href="https://fbads-vm.netlify.app/cancel-and-refund.html" radius={"xl"} compact variant="subtle" >
              <Text color={"gray"} weight={400} size={'xs'}>
                Cancel & refund
              </Text>
            </Button>
          </Group> */}

        {/* </Stack> */}
      </div>


    </div>

  );
}
