import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Center,
  Alert,
  Group,
  Title,
  Text,
  Affix,
  Accordion,
  TextInput,
  Divider,
  Stack,
  Grid,
  Badge,
  ActionIcon,
  LoadingOverlay,
} from "@mantine/core";
import { IconCopy, IconCreditCard, IconDiscount2, IconX } from "@tabler/icons";
import {
  query,
  collection,
  getFirestore,
  getDocs,
  where,
} from "firebase/firestore";
import firebase from "./../../lib/Firebase";
import bg from "./../../assets/money.jpg";
import { useAuth } from "../../lib/authUserContext";
import axios from "axios";
import { API_URL } from "./../../env";
import { useNavigate } from "react-router-dom";
const db = getFirestore(firebase);
const Checkout = (props) => {
  const { authUser, loading } = useAuth();
  const [initAmt, setInitAmt] = useState(1700);
  const [promos, setPromos] = useState([]);
  const navigate = useNavigate();
  const [l1, setL1] = useState(false);
  const [l2, setL2] = useState(false);
  const [prInVal, setPrInVal] = useState("");

  const totalPromo = promos.reduce((sum, val) => sum + val.amt, 0);
  const [discounts, setDiscounts] = useState({
    "early-adopters": 100,
    promocode: totalPromo,
  });
  const totalDiscount = discounts["early-adopters"] + totalPromo;
  const amt = initAmt - totalDiscount;

  let avPromo = "india";
  const mnth = new Date().getMonth();
  console.log('mnth', mnth)
  if ([0, 1, 2].includes(mnth)) {
    avPromo = "newyear";
  }
  if ([3, 4, 5].includes(mnth)) {
    avPromo = "summer";
  }
  if ([6, 7, 8].includes(mnth)) {
    avPromo = "india";
  }
  if ([9, 10, 11].includes(mnth)) {
    avPromo = "winter";
  }

  const syncSum = () => {
    setDiscounts((s) => {
      return {
        ...s,
        promocode: promos.reduce((sum, val) => sum.code + val.code, 0),
      };
    });
    // setAmt(flatAmt - (discounts["early-adopters"] + discounts.promocode));
  };
  const handleCheckout = () => {
    if(!authUser) return navigate("/home"); 
    setL2(true);
    axios
      .post(
        `${API_URL}/pg/checkout`,
        { initAmt, discounts, promos, amt, sub_plan: "sub_plan_basic" },
        {
          headers: {
            Authorization: `Bearer ${authUser?.jwt}`,
          },
        }
      )
      .then((res) => {
        navigate("/home");
        window.open(res.data.url, "_blank", "noopener,noreferrer");
      })
      .catch(alert)
      .finally(() => setL2(false));
  };
  const removePromo = (code) => {
    code = String(code).toLowerCase();
    const _promos = [...promos];
    const i = promos.findIndex((val) => val.code == code);
    _promos.splice(i, 1);
    setPromos(_promos);
  };
  const copyPromo = async () => {
    applyPromo(avPromo);
    // navigator.clipboard.writeText(copyText.value);
  };
  const applyPromo = async (code) => {
    code = String(code).toLowerCase();
    const i = promos.findIndex((val) => val.code == code);
    if (promos.length >= 1) {
      alert("Max promocodes");
      return;
    }
    if (code.length <= 1) {
      alert("Wrong promocode");
      return;
    }

    if (i > -1) {
      alert("Promocode already applied");
      return;
    }
    setL1(true);

    const colRef = collection(db, "promocodes");
    const q = query(colRef, where("code", "==", code));
    const snap = await getDocs(q);
    if (!snap.empty) {
      setPromos((state) => [...state, snap.docs[0].data()]);
    } else {
      alert("Invalid promocode");
    }
    syncSum();
    setL1(false);
  };
  const Promocode = ({ amt, code }) => {
    return (
      <Box
        sx={(theme) => ({
          backgroundColor:
            theme.colorScheme === "dark"
              ? theme.colors.dark[6]
              : theme.colors.gray[0],
          textAlign: "center",
          padding: theme.spacing.xs,
          paddingLeft: theme.spacing.md,
          paddingRight: theme.spacing.md,
          borderRadius: 0,
        })}
      >
        <Group position="apart">
          <Group>
            <Text size={"xs"} transform={"uppercase"}>
              {code}
            </Text>
            <Text size={"sm"} weight={500} color="#5B8844">
              ₹{amt}
            </Text>
          </Group>
          <ActionIcon
            onClick={() => removePromo(code)}
            size="xs"
            radius="xl"
            variant="transparent"
          >
            <IconX size={20} />
          </ActionIcon>
        </Group>
      </Box>
    );
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        backgroundImage: `url(https://cdn.wallpapersafari.com/59/20/OXtZEl.jpg)`,
        // backgroundPosition:'center',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundSize: "700px 700px",
        backgroundRepeat: "repeat",
        position: "relative",
      }}
    >
      <Affix position={{ top: 0, left: 0 }}>
        <Box
          sx={(theme) => ({
            backgroundColor: theme.colors.green[9],
            textAlign: "center",
            padding: theme.spacing.xs,
            paddingLeft: theme.spacing.md,
            paddingRight: theme.spacing.md,
            width: "100vw",
            borderRadius: 0,
          })}
        >
          <Group position="center" spacing={5}>
            <Text
              sx={(theme) => ({
                color: theme.colors.gray[0],
              })}
              weight={500}
              // transform="uppercase"
            >
              🥳 Use Promo Code <b style={{textTransform:'uppercase'}} >{avPromo}</b>
            </Text>
            <Button
            disabled={promos.length >= 1}
              compact
              radius={"xl"}
              variant="white"
              onClick={copyPromo}
              sx={(theme) => ({
                backgroundColor: theme.colors.gray[0],
              })}
              rightIcon={
                <ActionIcon
                  size="xs"
                  sx={(theme) => ({
                    color: theme.colors.dark[9],
                  })}
                  radius="xl"
                  variant="outline"
                >
                  <IconDiscount2 size={20} />
                </ActionIcon>
              }
            >
              <Text
                size={"sm"}
                sx={(theme) => ({
                  color: theme.colors.dark[9],
                })}
              >
                Apply
              </Text>
            </Button>
            <Text
              sx={(theme) => ({
                color: theme.colors.gray[0],
              })}
              weight={500}
            >
              and Get <b>₹400</b> discount on your subscription plan for
              lifetime
            </Text>
          </Group>
        </Box>
      </Affix>

      <div
        style={{
          borderRadius: "1rem",
          padding: "1rem",
          boxShadow:
            "0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.02)",
          backgroundColor: "white",
          width: "98vw",
          maxWidth: "500px",
          position: "relative",
        }}
      >
        <LoadingOverlay visible={l2 || loading} />
        <Stack align="center" spacing={2}>
          <Title>₹{amt}</Title>
          <Group spacing={"xs"}>
            <Text
              weight={600}
              style={{ textDecoration: "line-through" }}
              color="dimmed"
            >
              ₹{initAmt}
            </Text>
            <Text weight={600} color="#008B72">
              {/* 45% OFF */}
              {Math.round((totalDiscount / initAmt) * 100)}% OFF
            </Text>
          </Group>
        </Stack>
        <Stack p="lg" spacing={0}>
          <Group position="apart">
            <Text weight={600}>Total discount applied</Text>
            <Text weight={700}>₹{totalDiscount}</Text>
          </Group>
          <Stack p="lg" pb={0} px="xl" m={0} spacing={0}>
            <Group position="apart">
              <Text size={"sm"}>Early Adopter discount (Limited)</Text>
              <Text size={"sm"} color="#5B8844" weight={600}>
                ₹100
              </Text>
            </Group>
            {promos.length > 0 && (
              <>
                <Divider my="xs" />
                <Group position="apart">
                  <Text size={"sm"}>Promocode applied</Text>
                  <Text size={"xs"} color="#5B8844" weight={600}>
                    ₹{totalPromo}
                  </Text>
                </Group>
                <Stack pt="sm" spacing={"xs"}>
                  {promos.map(({ code, amt }) => {
                    return <Promocode key={code} amt={amt} code={code} />;
                  })}
                  {/* <Promocode amt={200} code={"SUMMER"} />
            <Promocode amt={200} code={"client@email.com"} /> */}
                </Stack>
              </>
            )}

            <Divider my="xs" />
          </Stack>

          {/* <Divider my="xs" /> */}
          <Group position="apart">
            <Text weight={600}>Total payable</Text>
            <Text weight={700}>₹{amt}</Text>
          </Group>
        </Stack>
        <Accordion variant="contained" defaultValue="customization">
          <Accordion.Item value="promo">
            <Accordion.Control icon={<IconDiscount2 size={21} />}>
              Apply promocode
            </Accordion.Control>
            <Accordion.Panel>
              <div>
                <Grid columns={10}>
                  <Grid.Col span={7}>
                    <TextInput
                      disabled={promos.length >= 1}
                      value={prInVal}
                      onChange={(event) =>
                        setPrInVal(event.currentTarget.value)
                      }
                      placeholder="PROMOCODE"
                    />
                  </Grid.Col>
                  <Grid.Col span={3}>
                    <Button
                      disabled={promos.length >= 1}
                      fullWidth
                      onClick={() => applyPromo(prInVal)}
                      loading={l1}
                      variant="filled"
                      color={"gray"}
                    >
                      Apply
                    </Button>
                  </Grid.Col>
                </Grid>
              </div>
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>
        <Button
          mt="md"
          fullWidth
          variant="filled"
          disabled={loading || authUser?.sub_status == 'active'}
          style={{ backgroundColor: "#5B8844" }}
          leftIcon={<IconCreditCard size={24} />}
          onClick={handleCheckout}
        >
          {authUser?.sub_status == 'active' ? `You already have a ${authUser?.sub_status} subscription` : 'Checkout'}
        </Button>
      </div>
    </div>
  );
};

export default Checkout;
