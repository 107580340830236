import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Card,
  Text,
  Loader,
  Group,
  Button,
  Stepper,
  Center,
  Modal,
  Box,
  Divider,
  Image,
  Grid,
  UnstyledButton,
  Title,
  Stack,
  Notification,
  Space,
  ThemeIcon,
} from "@mantine/core";
import { IconBrandWhatsapp, IconMail } from "@tabler/icons";
import { SUPPORT_EMAIL } from "../env";
function onEmailClick() {
  window.open(
    `mailto://${SUPPORT_EMAIL},pulkitgottoolkit@gmail.com?cc=itsnikhilsaini270@gmail.com`,
    "_blank",
    "noopener,noreferrer"
  );
}
function onWhatsappClick() {
  window.open(
    "https://wa.me/916375063918?text=Hey%2C%20I%20want%20to%20talk%20to%20you%20about%20Light%20Pc",
    "_blank",
    "noopener,noreferrer"
  );
}

const ContactModal = (props) => {
  return (
    <>
      <Modal
        size="sm"
        opened={props.opened}
        onClose={() => props.setOpened(false)}
      >
        <Text align="center" size="lg" weight={600}>
          Contact Us
        </Text>
        <Divider my={"xs"} />
        <Stack position="center">
          <Text
            align="center"
            color="blue"
            component="a"
            target={"_blank"}
            href={`mailto://${SUPPORT_EMAIL},pulkitgottoolkit@gmail.com?cc=itsnikhilsaini270@gmail.com`}
          >
            {SUPPORT_EMAIL}
          </Text>
          <Text
            align="center"
            color="blue"
            component="a"
            href={`tel:+916375063918`}
          >
            +916375063918
          </Text>
        </Stack>

        {/* <Text align="center" color="blue" component="a" target={"_blank"} href={`mailto://lightpc@lightpc.in`}>
          lightpc@lightpc.in
        </Text>
        <Text align="center" color="blue" component="a" target={"_blank"} href={`mailto://pulkitgottoolkit@gmail.com`}>
          pulkitgottoolkit@gmail.com
        </Text> */}
        <Group mt="1rem" position="center" grow>
          <Button
            onClick={onEmailClick}
            variant="light"
            color="grape"
            leftIcon={<IconMail size={14} />}
          >
            Email
          </Button>
          <Button
            onClick={onWhatsappClick}
            variant="light"
            color="green"
            leftIcon={<IconBrandWhatsapp size={14} />}
          >
            Whatsapp
          </Button>
        </Group>
      </Modal>
    </>
  );
};
export default ContactModal;
